import React from 'react';
import dynamic from 'next/dynamic';
import Layout from '../components/layout';
import {FaLocationArrow, FaMap} from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import cookie from 'js-cookie';
const AddToHomeScreenWithNoSSR = dynamic(() => import('react-add-to-homescreen'), {ssr: false});
import AddToHomeScreenModal from '../components/AddToHomeScreenModal';
import OverlayPanel from '../components/OverlayPanel';
const GoogleMapViewWithNoSSR = dynamic(() => import('../components/GoogleMapView'), {ssr: false});
import { FOLDER_NAMES_BY_KEY, FOLDER_NAMES_ORDERING, DEFAULT_LOCATION } from '../constants';
import MapUtils from "../utils/MapUtils";
import Analytics from "../utils/ga";
import '../styles/_index.scss';
import '../styles/_pwa-modal.scss';


class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      map: null,
      showAddToHomeScreenPrompt: false,
      showAddToHomeScreenModal: false,
      selectedCategory: 'all',
      allPlacemarks: null,
      filteredPlacemarks: null,
      currentCoords: null,
      locationError: false,
    };

    this.onMapLoaded = this.onMapLoaded.bind(this);
    this.onPlacemarksLoaded = this.onPlacemarksLoaded.bind(this);
    this.onPlaceBtnClicked = this.onPlaceBtnClicked.bind(this);
    this.onCategoryBtnClicked = this.onCategoryBtnClicked.bind(this);
    this.fetchUserLocation = this.fetchUserLocation.bind(this);
    this.watchUserLocation = this.watchUserLocation.bind(this);
    this.onFocusLocationBtnClicked = this.onFocusLocationBtnClicked.bind(this);
    this.onCenterLocationBtnClicked = this.onCenterLocationBtnClicked.bind(this);
    this.onAddToHomescreenClicked = this.onAddToHomescreenClicked.bind(this);
  }

  componentDidMount() {
    // Only called on client
    this.setState({
      showAddToHomeScreenPrompt: this.shouldShowAddToHomeScreenPrompt(),
    });

    cookie.set('vcsj.pwaPromptShown', 1);
  }

  shouldShowAddToHomeScreenPrompt() {
    return cookie.get('vcsj.pwaPromptShown') === undefined;
  }

  /**
   * Asks the user for permission to get location.  If granted, then will create blue marker and
   * adds it to the map, and then watches the user's location (and updates the map accordingly)
   * @param cb  Optional callback that is called after getting location and updating the map
   */
  fetchUserLocation(cb) {
    const thisComp = this;
    if (navigator.geolocation) {

      // As for user's location
      navigator.geolocation.getCurrentPosition(function(pos) {
        // Create a marker for the blue dot
        const myLocationMarker = MapUtils.createCurrentLocationMarker(pos, thisComp.state.map);

        thisComp.setState({
          currentLocationMarker: myLocationMarker,
          currentCoords: pos.coords
        }, function() {
          // Track user's location as it moves
          thisComp.watchUserLocation();

          // If callback provided, call it
          if (cb) {
            cb();
          }
        });
      }, function(error) {
        // Most likely means the user denied location permissions
        thisComp.setState({locationError: true});
      });
    }
  }

  watchUserLocation() {
    /**
     * Watches the users location and updates the current location marker.  Assumes user has already
     * granted permission
     */
    const thisComp = this;
    navigator.geolocation.watchPosition(
      function (pos) {
        const me = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
        thisComp.state.currentLocationMarker.setPosition(me);
        thisComp.setState({
          currentCoords: pos.coords
        });
    });
  }

  getPlacemarksForCategory() {
    if (this.state.allPlacemarks === null) {
      return []
    }
    if (this.state.selectedCategory === 'all') {
      let placemarks = []
      for (let folderName of FOLDER_NAMES_ORDERING) {
        placemarks.push(...this.state.allPlacemarks.filter(p => p.folderName === folderName))
      }
      return placemarks
    }

    const folderName = FOLDER_NAMES_BY_KEY[this.state.selectedCategory];
    return this.state.allPlacemarks.filter(p => p.folderName === folderName);
  }

  onMapLoaded(map) {
    this.setState({map: map});
  }

  onPlacemarksLoaded(placemarks){
    this.setState({
      allPlacemarks: placemarks,
    })
  }

  onCategoryBtnClicked(event) {
    const categoryKey = event.currentTarget.attributes.categorykey.value;
    this.setState({
      selectedCategory: categoryKey,
    }, function() {
      Analytics.event({category: 'user_action', action: 'filtered_on_category', label: categoryKey});
    });
  }

  onPlaceBtnClicked(event) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const idx = parseInt(event.currentTarget.attributes.markeridx.value);
    const filteredPlacemarks = this.getPlacemarksForCategory();
    const placemarkClicked = filteredPlacemarks[idx];
    if (placemarkClicked) {
      const marker = filteredPlacemarks[idx].marker;
      this.state.map.panTo(marker.getPosition());
      this.state.map.setZoom(16);
      google.maps.event.trigger(marker, "click");

      Analytics.event({category: 'user_action', action: 'placemark_card_tapped', label: placemarkClicked.name});
    }
  }

  onFocusLocationBtnClicked(event){
    if (this.state.currentCoords === null) {
      // We dont have user coords yet, so ask for it first
      this.fetchUserLocation(() => MapUtils.focusOnMapLocation(
        this.state.map,
        this.state.currentCoords.latitude,
        this.state.currentCoords.longitude,
        16
      ));

    } else {
      // We already have user location so just focus the map on them
      MapUtils.focusOnMapLocation(
        this.state.map,
        this.state.currentCoords.latitude,
        this.state.currentCoords.longitude,
        16
      );
    }
  }

  /**
   * Centers the map on the "default location" showing the full event route
   */
  onCenterLocationBtnClicked(event){
    MapUtils.focusOnMapLocation(this.state.map, DEFAULT_LOCATION.lat, DEFAULT_LOCATION.lng, DEFAULT_LOCATION.zoom);
  }

  onAddToHomescreenClicked(event) {
    this.setState({showAddToHomeScreenModal: true})
  }

  onAddToHomeScreenModalShow() {
    Analytics.event({category: 'user_action', action: 'pwa_modal_shown', label: 'PWA Modal Shown'});
    this.setState({showAddToHomeScreenPrompt: false})
  }

  render() {
    return (
      <Layout>
        <div className='index-page'>
          <div className='map-container'>
            <GoogleMapViewWithNoSSR
              selectedCategory={this.state.selectedCategory}
              onMapLoaded={this.onMapLoaded}
              onPlacemarksLoaded={this.onPlacemarksLoaded} />
          </div>

          <div className="top-buffer">
            <div className="focus-btns">
              {this.state.locationError === false && (
                <div className="focus-location-btn shadow-lg" onClick={this.onFocusLocationBtnClicked}>
                  <FaLocationArrow size="1.35em"/>
                </div>
              )}
              <div className="focus-center-btn shadow-lg" onClick={this.onCenterLocationBtnClicked}>
                <FaMap size="1.35em"/>
              </div>
            </div>
          </div>

          <OverlayPanel
            placemarks={this.getPlacemarksForCategory()}
            selectedCategory={this.state.selectedCategory}
            onPlaceBtnClicked={this.onPlaceBtnClicked}
            onCategoryBtnClicked={this.onCategoryBtnClicked}
          />

          {this.state.showAddToHomeScreenPrompt === true && (
            <>
              <AddToHomeScreenWithNoSSR
                title="Download this app!"
                icon="/static/icon-180.jpg"
                onAddToHomescreenClick={this.onAddToHomescreenClicked}
              />
            </>
          )}
          <Modal
            size="sm"
            centered
            show={this.state.showAddToHomeScreenModal}
            onEnter={() => this.onAddToHomeScreenModalShow()}
            onHide={() => this.setState({showAddToHomeScreenModal: false})}
            dialogClassName="pwa-modal"
          >
           <AddToHomeScreenModal onDoneClicked={() => this.setState({showAddToHomeScreenModal: false})} />
          </Modal>
        </div>
      </Layout>
    )
  }
}

export default Index;