export default class MapUtils {
  /**
   * Given a position, creates a "current location" marker on the map at that position
   * @param pos  The user's current location coords
   * @param map  The map instance
   * @returns {google.maps.Marker}  The newly created marker
   */
  static createCurrentLocationMarker(pos, map) {
    const myLocationMarker = new google.maps.Marker({
      clickable: false,
      icon: new google.maps.MarkerImage('/static/bluedot.png',
        new google.maps.Size(30, 30)),
      shadow: null,
      zIndex: 999,
      map: map
    });
    const me = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
    myLocationMarker.setPosition(me);

    return myLocationMarker;
  }

  /**
   * Focuses on a coordinate in a google map instance
   * @param map  The google map instance
   * @param lat  Latitude
   * @param lng  Longtitude
   * @param zoom  The zoom level
   */
  static focusOnMapLocation(map, lat, lng, zoom) {
    map.panTo(new google.maps.LatLng(lat, lng));
    map.setZoom(zoom);
  }

  /**
   * Creates a URL for "directions to" a given placemark
   * @param placemark  A placemark object from the geoxml lib
   * @returns {string} The url
   */
  static getDirectionsUrl(placemark) {
    const url = 'http://maps.google.com/maps?' + placemark.vars.directions.join('&');
    const address = encodeURIComponent(
      placemark.vars.val.address || placemark.latlng.toUrlValue()
    ).replace(/\%20/g, '+');
    return `${url}&daddr=${address}`;
  }
}