import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { FaStar, FaCarAlt, FaHeart, FaMapSigns, FaMapMarker} from 'react-icons/fa';
import PlacemarkCard from '../components/PlacemarkCard';
import ActivityHubCard from '../components/ActivityHubCard';
import { FOLDER_NAMES_BY_KEY } from '../constants';
import "../styles/_overlay-panel.scss"


export default class OverlayPanel extends Component {

  renderCategoryButtons() {
    const btnClasses = "category-btn ";
    const allClasses = btnClasses + (this.props.selectedCategory === 'all' ? 'active': '');
    const actClasses = btnClasses + (this.props.selectedCategory === 'actHubs' ? 'active': '');
    const attractionsClasses = btnClasses + (this.props.selectedCategory === 'attractions' ? 'active': '');
    const carClasses = btnClasses + (this.props.selectedCategory === 'crossingSpots' ? 'active': '');
    const pitClasses = btnClasses + (this.props.selectedCategory === 'pitStops' ? 'active': '');

    return(
      <ButtonToolbar className="category-btns">
        <div
          className={allClasses}
          key={1}
          categorykey='all'
          onClick={this.props.onCategoryBtnClicked}>
          <FaMapSigns size="2em" />
          <p>Overview</p>
        </div>
        <div
          className={actClasses}
          key={2}
          categorykey='actHubs'
          onClick={this.props.onCategoryBtnClicked}>
          <FaStar size="2em" />
          <p>Activity Hubs</p>
        </div>
        <div
          className={attractionsClasses}
          key={3}
          categorykey='attractions'
          onClick={this.props.onCategoryBtnClicked}>
          <FaHeart size="2em" />
          <p>Attractions</p>
        </div>
        <div
          className={pitClasses}
          key={4}
          categorykey='pitStops'
          onClick={this.props.onCategoryBtnClicked}>
          <FaMapMarker size="2em" />
          <p>Pit Stops</p>
        </div>
        <div
          className={carClasses}
          key={5}
          categorykey='crossingSpots'
          onClick={this.props.onCategoryBtnClicked}>
          <FaCarAlt size="2em"/>
          <p>Crossings</p>
        </div>
      </ButtonToolbar>
    )
  }

  renderActivityHubs() {
    let btns = [];
    if (this.props.placemarks !== null) {
      for (const [idx, p] of this.props.placemarks.entries()) {
        btns.push(
          <ActivityHubCard
            key={idx}
            idx={idx}
            placemark={p}
            onPlaceBtnClicked={this.props.onPlaceBtnClicked}
          />)
      }
    }
    return (
      <>
        {btns}
      </>
    )
  }

  renderPlacemarkButtons() {
    let btns = [];
    if (this.props.placemarks !== null) {
      for (const [idx, p] of this.props.placemarks.entries()) {
        if (p.folderName === FOLDER_NAMES_BY_KEY["routes"]) {
          continue;
        }
        else if (p.folderName === FOLDER_NAMES_BY_KEY["actHubs"]) {
          btns.push(
            <ActivityHubCard
              key={idx}
              idx={idx}
              placemark={p}
              onPlaceBtnClicked={this.props.onPlaceBtnClicked}
            />)
        }
        else {
          btns.push(
            <PlacemarkCard
              key={idx}
              idx={idx}
              placemark={p}
              onPlaceBtnClicked={this.props.onPlaceBtnClicked}
            />)
        }
      }
    }
    return (
      <>
        {btns}
      </>
    )
  }

  render() {
    return (
      <div className="overlay-panel">
        {this.renderCategoryButtons()}
        {this.props.selectedCategory === 'pitStops' && (
          <i className="tab-section-header">Stop by these locations for an exclusive deal of the day!</i>
        )}
        {this.renderPlacemarkButtons()}
      </div>
    );
  }
}

OverlayPanel.propTypes = {
  placemarks: PropTypes.array,
  selectedCategory: PropTypes.string.isRequired,
  onPlaceBtnClicked: PropTypes.func.isRequired,
  onCategoryBtnClicked: PropTypes.func.isRequired,
};