import React from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaMapMarkedAlt } from 'react-icons/fa'
import '../styles/_placemark-card.scss';

function createMarkup(content) {
  return {__html: content};
}

export default function PlacemarkCard(props) {
  return (
    <Button
      className='placemark-card shadow-sm'
      markeridx={props.idx}
      onClick={props.onPlaceBtnClicked}
      block >
      <Row>
        <Col xs={10} className="info-col">
          <p className="card-title">{props.placemark.name}</p>
          <p className="card-subtitle" dangerouslySetInnerHTML={createMarkup(props.placemark.description)}></p>
        </Col>
        <Col xs={2} className="map-col">
          <div className="map-link">
            <FaMapMarkedAlt size="2em"/>
          </div>
        </Col>
      </Row>
    </Button>
  )
}