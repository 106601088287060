import React, { useState } from 'react';
import Head from 'next/head';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import InfoScreen from './InfoScreen';
import { FaInfoCircle, FaTimesCircle } from 'react-icons/fa';
import Analytics from '../utils/ga';
import "../styles/_base.scss";


export default function Layout(props) {
  const [showingInfoModal, setShowingInfoModal] = useState(false);

  return (
    <div>
      <Head>
        {Analytics.script()}

        <title>Viva Calle SJ</title>
        <meta charSet='utf-8'/>
        <meta name='viewport' content='initial-scale=1.0, width=device-width, viewport-fit=cover'/>
        <meta name="theme-color" content="#75c5d1"/>

        <link rel="manifest" href="/static/manifest.json"/>
        <link rel="apple-touch-icon" href="/static/icon-180.jpg"/>

        <link href="https://fonts.googleapis.com/css?family=Muli:400,600,800&display=swap" rel="stylesheet"/>
        <link rel="stylesheet"
              href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
              integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
              crossOrigin="anonymous"/>

        <script src="../static/geoxml3.js"/>
      </Head>

      <Navbar className="header-navbar" bg="light" fixed="top">
        <Navbar.Brand className="mr-auto" href="/">
          <img className="nav-logo" src="/static/nav-header-logo.png" alt="Viva Calle SJ"/>
        </Navbar.Brand>
        <Nav className="info-link" onClick={() => setShowingInfoModal(!showingInfoModal)}>
          {showingInfoModal === false ? (
            <>
              <FaInfoCircle size="2.0em" />
              <p>Info</p>
            </>
          ): (
            <>
              <FaTimesCircle size="2.0em" />
              <p>Close</p>
            </>
          )}

        </Nav>
      </Navbar>

      <div className={showingInfoModal ? "d-none" : "page-content"}>
        {props.children}
      </div>

      <Modal
        show={showingInfoModal}
        backdrop={false}
        onEnter={() => Analytics.event({category: 'user_action', action: 'info_screen_viewed', label: 'Info Modal'})}
        onHide={() => setShowingInfoModal(false)}
        dialogClassName="info-modal"
      >
       <InfoScreen backToMap={() => setShowingInfoModal(false)}/>
      </Modal>

    </div>
  )
}