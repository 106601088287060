import * as foldersJson from "./static/folders.json";
export const FOLDER_NAMES_BY_KEY = foldersJson.default.folders;
export const FOLDER_NAMES_ORDERING= foldersJson.default.ordering;

export const MAP_VERSION = 'v41';

export const DEFAULT_LOCATION = {
  lat: 37.338,
  lng: -121.900,
  zoom: 12.9
};

export const GOOGLE_MAP_STYLES = [
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];