import React from "react";
import Button from "react-bootstrap/Button";
import '../styles/_activity-hub-card.scss';

function createMarkup(content) {
  return {__html: content};
}

export default function ActivityHubCard(props) {
  return (
    <Button
      className='activity-hub-card shadow-sm'
      markeridx={props.idx}
      onClick={props.onPlaceBtnClicked}
    >
      <div className="ah-title-and-logo">
        <p className="ah-title">{props.placemark.name}</p>
        <div className='ah-logo' />
      </div>
      <p className="ah-subtitle" dangerouslySetInnerHTML={createMarkup(props.placemark.description)}></p>
    </Button>
  )
}