import React from 'react';
import Button from 'react-bootstrap/Button';


export default function AddToHomeScreenModal(props) {
  return (
    <div className="pwa-modal-inner-content">
      <h2>Install Viva CalleSJ</h2>
      <p>Install this application on your home screen for quick and easy access throughout the event.</p>
      <div className="text-and-share">
        Just tap <img src="/static/share.png" className="share-img" /> and then 'Add to Homescreen'
      </div>

      <Button variant="primary"
              size="lg"
              className="done-btn"
              onClick={props.onDoneClicked}>
        Got It
      </Button>
    </div>
  )
}